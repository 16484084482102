@import '@polleverywhere/assets/lib/assets/stylesheets/pollev_assets/fonts/mixin';
@import '@polleverywhere/participants-theme/style';
@import '@polleverywhere/toolkit-response/lib/activity/style';

notification-manager {
  position: absolute;
  width: 100%;
  max-width: 24.5rem;
  z-index: 100;
  padding: 2rem;
  top: 0;
  right: 0;
  pointer-events: none;

  > pe-notification {
    pointer-events: auto;
  }
}

$modern-theme-to-legacy: (
  header-background: 'header-bg',
  header-text: 'header-text',
  background: 'bg',
  text: 'text',
  link: 'text',
  rule: 'text',
  button-background: 'button-bg',
  button-text: 'button-text',
  tab-background: 'highlight-bg',
  tab-text: 'highlight-text',
  tab-active-background: 'button-bg',
  tab-active-text: 'button-text',
  response-item-background: 'secondary-bg',
  response-item-text: 'secondary-text',
  response-item-button-background: 'button-bg',
  response-item-button-highlight-background: 'highlight-bg',
  response-item-button-text: 'button-text',
  response-item-button-highlight-text: 'highlight-text',
  sub-response-item-background: 'button-bg',
  sub-response-item-text: 'button-text'
);

body {
  @each $var, $val in $participants-theme-default {
    $default: $val;
    $modern-key: map-get($modern-theme-to-legacy, $var);

    #{$participants-theme-prefix}#{$var}: var(--participate-#{$modern-key}, $default);
  }
}

.legacy-activity-container[id^='legacy_survey'] {
  padding: 0;

  .component-response-activity {
    padding: 0;
    position: unset;
  }

  .component-response-survey {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;

    &__questions--multi-page {
      flex-direction: column-reverse;
    }
  }
}

// Override the legacy participant viz chart 'grid' styles to ensure tailwind takes precedence in the nav
@media (min-width: 768px) {
  nav .md\:flex-col {
    flex-direction: column;
  }
}

pe-graphic > svg.pe-graphic.pe-graphic--mono-stroke path,
pe-graphic > svg.pe-graphic.pe-graphic--mono-stroke circle {
  stroke: var(--pe-graphic-color, var(--foreground-color, #0f172a));
}

pe-graphic > svg.pe-graphic.pe-graphic--mono-fill circle,
pe-graphic > svg.pe-graphic.pe-graphic--mono-fill path {
  fill: var(--pe-graphic-color, var(--foreground-color, #0f172a));
}
